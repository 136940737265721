import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../utils/axiosInstance';

const initialState = {
  DiamondList: [],
  diamondId: [],
  diamond: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    createDiamondInventory(state, action) {
      state.isLoading = false;
      state.diamond = action.payload;
    },
  
    inventoryDiamondList(state, action) {
      state.isLoading = false;
      state.DiamondList = action.payload;
    },   

    diamondById(state, action) {
      state.isLoading = false;
      state.diamondId = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { DiamondList, diamond, deleteMasterCosts, createmasterCosts } = slice.actions;

// ----------------------------------  Create Inventory Diamond ------------------------------------


export function CreateDiamondInventory(diamond) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-diamonds', diamond);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error; 
    }
  };
}

// ----------------------------------  Update Inventory Diamond ------------------------------------


export function UpdateDiamondInventory(diamond) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-diamonds/${diamond.id}`, diamond);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error; 
    }
  };

}


// ----------------------- Inventory Diamond List -------------------------------------

export function getInventoryDiamond() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-diamonds');

      dispatch(slice.actions.inventoryDiamondList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Diamond Id wise  List -------------------------------------

export function getDiamondById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-diamonds/${id}`);
      dispatch(slice.actions.diamondById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

